<template>
  <div>
    <!-- <Header :url="require('@/assets/images/member.png')" /> -->
    <Header :banner="bannerList" />
    <Menu :menuList="menuList"></Menu>
    <div class="container" id="a1">
      <div class="row">
        <div v-for="(item, inx) in memberList" :key="inx" class="col col-lg-3 col-sm-12">
          <div class="aboutOne cursor" data-bs-toggle="collapse" :data-bs-target="'#con' + inx" aria-expanded="false"
            :aria-controls="'con' + inx">
            <p class="oneTitle mb-xl-1">{{ item.title }}</p>
            <p class="threeTitle" v-html="item.content1"></p>
          </div>
          <div class="collapse" :id="'con' + inx" style="margin-top: -4rem">
            <div class="aboutOne auto">
              <p class="threeTitle" v-html="item.content2"></p>
            </div>
          </div>
        </div>
        <div class="col col-lg-3 col-sm-12">
          <div class="aboutOne cursor active" @click="showWechat=true">
            <p class="oneTitle mb-xl-1">申请入会</p>
            <p class="threeTitle flexStart">
              <img src="@/assets/images/g1.png" alt="" class="memberImgs">
              <span class="flexBtn menWord mb0">
                专业会员
                <img src="@/assets/images/bottom.png" alt="" class="rightImg">
              </span>
            </p>
            <p class="threeTitle flexStart">
              <img src="@/assets/images/g2.png" alt="" class="memberImgs">
              <span class="flexBtn menWord mb0">
                团体会员
                <img src="@/assets/images/bottom.png" alt="" class="rightImg">
              </span>
            </p>
            <p class="threeTitle flexStart">
              <img src="@/assets/images/g3.png" alt="" class="memberImgs">
              <span class="flexBtn menWord mb0">
                学生会员
                <img src="@/assets/images/bottom.png" alt="" class="rightImg">
              </span>
            </p>
            <p class="threeTitle flexStart">
              <img src="@/assets/images/g3.png" alt="" class="memberImgs">
              <span class="flexBtn menWord mb0">
                联系会员
                <img src="@/assets/images/bottom.png" alt="" class="rightImg">
              </span>
            </p>
          </div>
        </div>
      </div>
      <div class="row" id="a2">
        <div class="col">
          <p class="defaultWord mt20 pb10">
            <span aria-controls="collapseExample" aria-expanded="false" class="cursor" data-bs-target="#collapseExample"
              data-bs-toggle="collapse">
              专业会员
              <img alt="" src="@/assets/images/bottom.png" style="width: 20px;margin-left: .5rem">
            </span>
          </p>
          <div class="line"></div>
          <div id="collapseExample" class="collapse show">
            <div class="row bigScroll">
              <div class="col" v-for="item in memberLogo1" :key="item.id">
                <div class="memHei">
                  <img :src="item.avatar" alt="">
                </div>
                <div class="menBtm flexCenter">{{ item.real_name }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row" id="a3">
        <div class="col">
          <p class="defaultWord mt20 pb10">
            <span aria-controls="student" aria-expanded="false" class="cursor" data-bs-target="#student"
              data-bs-toggle="collapse">
              学生会员
              <img alt="" src="@/assets/images/bottom.png" style="width: 20px;margin-left: .5rem">
            </span>
          </p>
          <div class="line"></div>
          <div id="student" class="collapse show">
            <div class="row bigScroll">
              <div class="col" v-for="item in memberLogo3" :key="item.id">
                <div class="memHei">
                  <img :src="item.avatar" alt="">
                </div>
                <div class="menBtm ac1 flexCenter">{{ item.real_name }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row" id="a4">
        <div class="col">
          <p class="defaultWord mt20 pb10">
            <span aria-controls="contact" aria-expanded="false" class="cursor" data-bs-target="#contact"
              data-bs-toggle="collapse">
              联系会员
              <img alt="" src="@/assets/images/bottom.png" style="width: 20px;margin-left: .5rem">
            </span>
          </p>
          <div class="line"></div>
          <div id="contact" class="collapse show">
            <div class="row bigScroll">
              <div class="col" v-for="item in memberLogo4" :key="item.id">
                <div class="memHei">
                  <img :src="item.avatar" alt="">
                </div>
                <div class="menBtm ac2 flexCenter">{{ item.real_name }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row" id="a5">
        <div class="col">
          <p class="defaultWord mt20 pb10">
            <span aria-controls="group" aria-expanded="false" class="cursor" data-bs-target="#group"
              data-bs-toggle="collapse">
              团体会员
              <img alt="" src="@/assets/images/bottom.png" style="width: 20px;margin-left: .5rem">
            </span>
          </p>
          <div class="line"></div>
          <div id="group" class="collapse show">
            <!--            row-cols-auto-->
            <div class="row">
              <div class="col " v-for="item in memberLogo" :key="item.id">
                <div class="memHei active">
                  <img :src="require(`@/assets/images/h${item.id}.png`)" alt="" style="width: 7rem">
                </div>
                <div class="mp1">{{ item.name }}</div>
                <div class="mp2">{{ item.desc }}</div>
              </div>
              <!-- <div class="col" v-for="item in memberLogo3" :key="item.id">
                <div class="memHei">
                  <img :src="item.avatar" alt="">
                </div>
                <div class="menBtm flexCenter">{{ item.real_name }}</div>
              </div>
              <div class="col">
                <div class="memHei active">

                </div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
      <!--      <div class="row">-->
      <!--        <div class="col" v-for="item in 8" :key="item">-->
      <!--          <div class="memHei">-->
      <!--            <div class="menBtm flexCenter">冰彩{{item}}</div>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->
    </div>
    <Footer></Footer>
    <div class="showWechat flexCenter" v-if="showWechat" @click="showWechat=false;">
      <img src="@/assets/images/wechat1.jpg" alt="">
    </div>
  </div>
</template>

<script>
export default {
  name: "about",
  data() {
    return {
      showWechat:false,
      menuList: [
        {
          id: 'a1',
          name: '全部会员',
        }, {
          id: 'a2',
          name: '专业会员',
        }, {
          id: 'a3',
          name: '学生会员',
        }, {
          id: 'a4',
          name: '联系会员',
        }, {
          id: 'a5',
          name: '团体会员',
        },
      ],
      memberList: [
        {
          title: '会员权益',
          con: `· 享有选举权、被选举权和表决权
（团体会员无选举权）<p></p>
· 会员享有参加本会活动的权利，可
免费参观本会的各种展览及免费设计
讲座(不含收费讲座)<p></p>· 可免费收到本会编辑的会刊，通讯
等资料(含国内外设计人物及作品`,
          conBox: `· 享有选举权、被选举权和表决权
（团体会员无选举权）<p></p>
· 会员享有参加本会活动的权利，可
免费参观本会的各种展览及免费设计
讲座(不含收费讲座)<p></p>· 可免费收到本会编辑的会刊，通讯
等资料(含国内外设计人物及作品`
        }, {
          title: '入会资格',
          con: `专业会员
A.国内外专业插画比赛作品入选及获
奖; B.作品的专业水准;C.商业、出版
案例;D.对插画行业包括教育推动性影
响、贡献等。递交作品不少于5件(屏
幕显示用电子文档即可)。范围包括:
插画创作及教育、平面设计、广告设
计、互联网设计、时装设计、环境及
空间设计等;`,
          conBox: `专业会员
A.国内外专业插画比赛作品入选及获
奖; B.作品的专业水准;C.商业、出版
案例;D.对插画行业包括教育推动性影
响、贡献等。递交作品不少于5件(屏
幕显示用电子文档即可)。范围包括:
插画创作及教育、平面设计、广告设
计、互联网设计、时装设计、环境及
空间设计等;`
        }, {
          title: '审核委员',
          con: `<p class="mb0">张忠阳</p>
<p>SIA创会会长/专业委员/CIB评委</p>
<p class="mb0">李旻</p>
<p>SIA专业委员/CIB评委</p>

<p class="mb0">朱求真</p>
<p>SIA执行会长/秘书长/CIB评委</p>`,
          conBox: `<p class="mb0">张忠阳</p>
<p>SIA创会会长/专业委员/CIB评委</p>
<p class="mb0">李旻</p>
<p>SIA专业委员/CIB评委</p>

<p class="mb0">朱求真</p>
<p>SIA执行会长/秘书长/CIB评委</p>`
        },
      ],
      bannerList: [],
      memberLogo1:[],
      memberLogo2:[],
      memberLogo3:[],
      memberLogo4:[],
      memberLogo: [
        {
          id: 1,
          name: '泱泱文化',
          desc: '会长单位'
        }, {
          id: 2,
          name: '视觉中国',
          desc: '副会长单位'
        }, {
          id: 3,
          name: '华为主题',
          desc: '理事单位'
        }, {
          id: 4,
          name: '名创优品',
          desc: '理事单位'
        }, {
          id: 5,
          name: '万兴科技',
          desc: '理事单位'
        }, {
          id: 6,
          name: '巨有文化',
          desc: '理事单位'
        }, {
          id: 7,
          name: '麦小朵',
          desc: '会员单位'
        },
      ]
    }
  },
  methods: {
     //banner
    get_two_banner() {
      this.$get('/api/get_two_banner', { type: 2 }).then(res => {
        this.bannerList = res.data.data;
      }).catch(err => {

      })
    },
    //会员权益
    get_index_hyqy() {
      this.$get('/api/get_index_hyqy', {}).then(res => {
        res.data.forEach(ele => {
          var content = ele.content.replace(/\n/g, '<br>')
          ele.content1 = content.substring(0, 100)
          ele.content2 = content.substring(100, content.length)
        });
        this.memberList = res.data;
      }).catch(err => {

      })
    },
    //会员列表
    get_index_hylist() {
      this.$get('/api/get_index_hylist', {page:1,limit:5000,type:1}).then(res => {
        this.memberLogo1 = res.data.data;
      }).catch(err => {

      })
      this.$get('/api/get_index_hylist', {page:1,limit:5000,type:2}).then(res => {
        this.memberLogo2 = res.data.data;
      }).catch(err => {

      })
      this.$get('/api/get_index_hylist', {page:1,limit:5000,type:3}).then(res => {
        this.memberLogo3 = res.data.data;
      }).catch(err => {

      })
      this.$get('/api/get_index_hylist', {page:1,limit:5000,type:4}).then(res => {
        this.memberLogo4 = res.data.data;
      }).catch(err => {

      })
    },
  },
  mounted() {
    this.get_index_hyqy()
    this.get_index_hylist()
    this.get_two_banner()
  }
}
</script>

<style scoped lang="scss">
.showWechat {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999;
  background: rgba($color: #000000, $alpha: .5);

  img {
    // border-radius: 50%;
  }
}
.bigScroll{
  max-height: 500px;
  overflow-y: scroll;

      &::-webkit-scrollbar {
          /*滚动条整体样式*/
          width: 6px;
          /*高宽分别对应横竖滚动条的尺寸*/
          height: 1px;
        }
      
        &::-webkit-scrollbar-thumb {
          /*滚动条里面小方块*/
          border-radius: 10px;
          -webkit-box-shadow: inset 0 0 5px rgba(1, 193, 178, 0.2);
          background: rgba($color: #fff078, $alpha: 1);
        }
      
        &::-webkit-scrollbar-track {
          /*滚动条里面轨道*/
          -webkit-box-shadow: inset 0 0 5px rgba(1, 193, 178, 0.2);
          border-radius: 10px;
          background: rgba($color: #fff078, $alpha: .2);;
        }
}
.aboutOne {
  width: 100%;
  background-color: #f0f0eb;
  padding: 1rem;
  color: #707070;
  text-align: left;
  // min-height: 17rem;
  overflow: hidden;
  margin-bottom: 1.5rem;
  min-width: 260px;

  &:hover {
    //height: auto;
  }

  &.active {
    background: #fff078;
  }

  .oneTitle {
    font-weight: 600;
    font-size: 1.8rem;
    margin-bottom: 0;
  }

  .twoTitle {
    font-size: 1.2rem;
    font-weight: 600;
  }

  .threeTitle {}

  .menWord {
    color: #4d4d4d;
    border-bottom: 1px solid #4d4d4d;
    //padding-bottom: 5px;
    flex: 1;
  }

  .memberImgs {
    width: 2rem;
    margin-right: .5rem;
  }

  .rightImg {
    transform: rotate(-90deg);
    width: 1rem;
  }
}

.memHei {
  width: 100%;
  max-height: 8rem;
  overflow: hidden;
  position: relative;
  // background: #afafaf;
  min-width: 7rem;
  img{
    width: 100%;
  }

  &.active {
    background: transparent;
  }


}

.menBtm {
  width: 100%;
  height: 1.5rem;
  color: #fff;
  background: #c9b472;
  margin-bottom: 1.5rem;
  font-size: 14px;

  //position: absolute;
  //left: 0;
  //bottom: 0;
  &.ac1 {
    background: #7d9de1;
  }

  &.ac2 {
    background: #7ca7b4;
  }
}

.mp1 {
  font-size: 1rem;
  color: #afafaf;
}

.mp2 {
  font-size: .8rem;
  color: #afafaf;
}
@media (min-width: 1400px) {
  .aboutOne {
    height: 18rem;
  }
}
</style>
